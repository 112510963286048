var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "300px", height: "430px", overflow: "none" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont cod_cont_area" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(" " + _vm._s(_vm.$t("msg.ONEX010T010.161")) + " "),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("div", { staticClass: "clearfix" }, [
            _c("div", { staticClass: "float_left" }, [
              _c(
                "table",
                {
                  staticClass: "tbl_list font_sm gridtype",
                  staticStyle: { border: "1px solid #e1e3eb" },
                },
                [
                  _vm._m(0),
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("No.")]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.003")))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.018")))]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.list, function (vo, idx) {
                      return _c("tr", { key: idx }, [
                        _c("td", [_vm._v(_vm._s(vo.rowCd))]),
                        _c("td", [_vm._v(_vm._s(vo.cntrNo))]),
                        _c("td", { staticStyle: { "text-align": "end" } }, [
                          _vm._v(
                            _vm._s(vo.rmk.replace(/\B(?=(\d{3})+(?!\d))/g, ","))
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]
              ),
            ]),
          ]),
          _vm._m(1),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "50%" } }),
      _c("col", { attrs: { width: "35%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "normal_box",
        staticStyle: { color: "red", "font-weight": "500" },
      },
      [
        _c("ol", [
          _c("li", [
            _vm._v(
              "CNTR 개별중량의 경우 자동 나누기 되어 입력되오니 SHIPPER측과 재 확인 부탁드립니다."
            ),
          ]),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }