<template>
  <div class="popup_wrap" style="width:300px; height:430px;overflow:none;">
    <button class="layer_close" @click="$emit('close')">close</button>

    <div class="popup_cont cod_cont_area">
      <!-- popup_cont -->
      <h1 class="page_title">
        {{ $t('msg.ONEX010T010.161') }}
      </h1>
      <div class="content_box">
        <div class="clearfix">
          <div class="float_left">
            <table class="tbl_list font_sm gridtype" style="border: 1px solid #e1e3eb;">
              <colgroup>
                <col width="15%">
                <col width="50%">
                <col width="35%">
              </colgroup>
              <thead>
                <tr>
                  <th>No.</th>
                  <th>{{ $t('msg.ONEX050G020.003') }}</th>
                  <th>{{ $t('msg.ONEX050G020.018') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in list" :key="idx">
                  <td>{{ vo.rowCd }}</td>
                  <td>{{ vo.cntrNo }}</td>
                  <td style="text-align: end;">{{ vo.rmk.replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="normal_box" style="color: red; font-weight: 500;">
          <ol>
            <li>CNTR 개별중량의 경우 자동 나누기 되어 입력되오니 SHIPPER측과 재 확인 부탁드립니다.</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import trans from '@/api/rest/trans/importSvc'
import { rootComputed } from '@/store/helpers'

export default {
  name: 'CntrSearchPop',
  components: {
    rootComputed
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          podCtrCd: ''
        }
      }
    }
  },
  data () {
    return {
      list: []
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    parentInfo () {
    }
  },
  created () {
    this.initPop()
  },
  methods: {
    async initPop () {
      //bkgNo없으면 blNo가져오기 처리
      const parentInfo = this.parentInfo
      if (this.$ekmtcCommon.isEmpty(parentInfo.blNo)) {
        const TH = this
        this.$ekmtcCommon.alertCallback(this.$t('msg.ONEX070G050.004'), () => {
          TH.$emit('close')
        })
      }

      this.init()
    },
    async init () {
      const TH = this
      const parentInfo = TH.parentInfo
      const blNo = parentInfo.blNo
      const params = {
        blNo: blNo
      }
      await trans.getCntrList(params)
      .then(res => {
        console.log('getCntrList res @@@@ ', res.data)
        this.list = res.data
      })
    }
  }
}
</script>

<style scoped>
.float_left{
  overflow-y: scroll;
  height: 170px;
  width: 100%;
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}

.float_left::-webkit-scrollbar {
  display: none; /* 크롬, 사파리, 오페라, 엣지 */
}
</style>
